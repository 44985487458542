<script setup>

    import { computed } from 'vue'
    import { util } from '@/Helpers'
    import moment from 'moment'
    import { storeToRefs } from 'pinia'
    import LiveInScheduleViewBlock from '@/Components/Caregiver/LiveInScheduleViewBlock.vue'
    import { useCaregiverSingleStore } from '@/Stores'

    const caregiverSingleStore = useCaregiverSingleStore()
    const { userFocusedOn } = storeToRefs(caregiverSingleStore)

    const props = defineProps({
        schedule_start_date: {
            type: String,
            required: true
        },
        block: {
            type: Object,
            required: true
        }
    })

    const isCurrentOrFutureWeek = computed(() => {
        const lastSunday = moment(util.date.lastSunday(), util.date.date_format)
        const startDate = moment(props.schedule_start_date, util.date.date_format)

        return startDate.isSameOrAfter(lastSunday);
    })

</script>

<template>
    <li
        class="relative flex group justify-center ml-05 mr-5"
        :class="[
            `col-start-${util.date.dateDiff(schedule_start_date, block.start_date) + 1}`,
            {
                'z-40': userFocusedOn == 'live-in',
                'z-10': userFocusedOn == 'live-out',
                'z-90': userFocusedOn == null,
            }
        ]"
        :style="`grid-row: ${util.schedule.startWithTz(block.start_time)} / span ${util.schedule.span(block.duration)}`"
    >
        <LiveInScheduleViewBlock
            :isCurrentOrFutureWeek="isCurrentOrFutureWeek"
            :block="block"
            :size="util.schedule.sizeOf(block)"
            :class="[
                'group absolute flex inset-0.5 flex-col overflow-y-auto text-xs whitespace-nowrap cursor-default opacity-70 border-dashed border-opacity-10 text-lifeworx-purple-600 bg-lifeworx-purple-600 border-lifeworx-purple-600',
                {
                    'rounded-t-lg': block.continues,
                    'rounded-b-lg': block.continuation,
                    'rounded-lg': !block.continues && !block.continuation,
                    'p-0.5 border-2': ['3xs', '2xs', 'xs'].includes(util.schedule.sizeOf(block)),
                    'p-1 border-2': ['sm', 'md', 'lg', 'xl', '2xl'].includes(util.schedule.sizeOf(block)),
                    'backdrop-brightness-200 backdrop-saturate-0 backdrop-blur-sm': block.overlaps,
                    'backdrop-blur-sm bg-opacity-[0.25]': block.id && block.overlaps,
                    'backdrop-brightness-200 backdrop-opacity-75 backdrop-blur-sm bg-opacity-[0.2] text-opacity-50': userFocusedOn !== 'live-in',
                    'backdrop-brightness-200 backdrop-opacity-100 backdrop-saturate-0 backdrop-blur-sm text-opacity-100 border-opacity-50 bg-opacity-[0.3]': userFocusedOn == 'live-in',
                }
            ]"
        />
    </li>
</template>
