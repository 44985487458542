<script setup>

    import { deepCopy, util } from '@/Helpers'
    import moment from 'moment'
    import { ref, computed, watch } from 'vue'

    import LatteSchedule from '@/Components/LatteSchedule.vue'
    import { useCaregiverClientShiftStore, useCaregiverInvoiceShiftStore } from '@/Stores';
    import ClientCaregiverViewBlock from '@/Components/Caregiver/ClientCaregiverViewBlock.vue';
    import LiveInViewBlock from '@/Components/Caregiver/LiveInViewBlock.vue';
    import LiveOutViewBlock from '@/Components/Caregiver/LiveOutViewBlock.vue';

    const emit = defineEmits(['shifts:selected'])

    const caregiverClientShiftStore = useCaregiverClientShiftStore()
    const caregiverInvoiceShiftStore = useCaregiverInvoiceShiftStore()

    const props = defineProps({
        caregiver_id: {
            type: Number,
            required: false
        },
        schedule_start_date: {
            type: String,
            required: true
        },
        formLoading: {
            type: Boolean,
            default: true
        },
        live_in_shifts: {
            type: Array,
            required: true
        },
        live_out_group_one_shifts: {
            type: Array,
            required: true
        },
        live_out_group_two_shifts: {
            type: Array,
            required: true
        }
    })

    let clientShifts
    let clientShiftBlocks
    let scheduleDayTimeDict
    let liveInBlocks
    let liveOutGroupOneBlocks
    let liveOutGroupTwoBlocks

    watch([() => props.caregiver_id, () => props.schedule_start_date], ([local_caregiver_id, local_start_date]) => {
        if (props.schedule_start_date >= util.date.lastSunday()) {
            clientShifts = caregiverClientShiftStore.computedShift(local_caregiver_id)
        } else {
            clientShifts = caregiverInvoiceShiftStore.computedShift(local_caregiver_id)
        }

        clientShiftBlocks = computed(() => {
            //Have to reference start date to trigger recompute
            if (props.schedule_start_date && props.caregiver_id) {}
            const clientShiftsClone = deepCopy(clientShifts.value)

            let data = []
            clientShiftsClone.forEach(shift => {
                if (util.schedule.isDateOverlap(props.schedule_start_date, shift.day_of_week, shift.start_date, shift.end_date, shift.start_time, shift.duration)) {
                    data = data.concat(util.schedule.shiftToBlock(shift, shift, props.schedule_start_date))
                }
            })
            return data
        })

        scheduleDayTimeDict = computed(() => {
            let dict = {
                sunday: {
                    live_in_0: [],
                    live_out_0: [],
                    live_out_1: []
                },
                monday: {
                    live_in_0: [],
                    live_out_0: [],
                    live_out_1: []
                },
                tuesday: {
                    live_in_0: [],
                    live_out_0: [],
                    live_out_1: []
                },
                wednesday: {
                    live_in_0: [],
                    live_out_0: [],
                    live_out_1: []
                },
                thursday: {
                    live_in_0: [],
                    live_out_0: [],
                    live_out_1: []
                },
                friday: {
                    live_in_0: [],
                    live_out_0: [],
                    live_out_1: []
                },
                saturday: {
                    live_in_0: [],
                    live_out_0: [],
                    live_out_1: []
                },
            }

            function buildDict(key, dict, shift)
            {
                dict[shift.day_of_week][key].push({
                    start_time:  shift.start_time,
                    duration: util.schedule.is2DaysTime(shift.start_time, shift.duration) ? util.date.getDayRemainMinute(shift.start_time) : shift.duration
                })

                if (util.schedule.is2DaysTime(shift.start_time, shift.duration)) {
                    const nextDay = util.date.nextDayOfWeek(shift.day_of_week)
                    dict[nextDay][key].push({
                        start_time: '00:00:00',
                        duration: shift.duration - util.date.getDayRemainMinute(shift.start_time)
                    })
                }

                return dict
            }

            props.live_in_shifts.forEach(shift => {
                dict = buildDict('live_in_0', dict, shift)
            })

            props.live_out_group_one_shifts.forEach(shift => {
                dict = buildDict('live_out_0', dict, shift)
            })

            props.live_out_group_two_shifts.forEach(shift => {
                dict = buildDict('live_out_1', dict, shift)
            })

            return dict
        })

        liveInBlocks = computed(() => {
            if (props.live_in_shifts) {}

            let data = []

            props.live_in_shifts.forEach(shift => {

                let blocks = util.schedule.shiftToBlock(shift, shift, props.schedule_start_date)
                blocks.forEach(block => {
                    scheduleDayTimeDict.value[block.day_of_week]['live_out_0'].forEach(info => {
                        if (util.schedule.isTimeOverlap(block.start_time, block.duration, info.start_time, info.duration)) {
                            block.overlaps = true
                        }
                    })
                    scheduleDayTimeDict.value[block.day_of_week]['live_out_1'].forEach(info => {
                        if (util.schedule.isTimeOverlap(block.start_time, block.duration, info.start_time, info.duration)) {
                            block.overlaps = true
                        }
                    })
                })

                data = data.concat(blocks)
            })

            return data
        })

        liveOutGroupOneBlocks = computed(() => {
            if (props.live_out_group_one_shifts) {}

            let data = []
            props.live_out_group_one_shifts.forEach(shift => {
                let blocks = util.schedule.shiftToBlock(shift, shift, props.schedule_start_date)
                blocks.forEach(block => {
                    block.group = 'a'
                    scheduleDayTimeDict.value[block.day_of_week]['live_in_0'].forEach(info => {
                        if (util.schedule.isTimeOverlap(block.start_time, block.duration, info.start_time, info.duration)) {
                            block.overlaps = true
                        }
                    })
                    scheduleDayTimeDict.value[block.day_of_week]['live_out_1'].forEach(info => {
                        if (util.schedule.isTimeOverlap(block.start_time, block.duration, info.start_time, info.duration)) {
                            block.overlaps = true
                        }
                    })
                })
                data = data.concat(blocks)
            })

            return data
        })

        liveOutGroupTwoBlocks = computed(() => {
            if (props.live_out_group_two_shifts) {}

            let data = []
            props.live_out_group_two_shifts.forEach(shift => {
                let blocks = util.schedule.shiftToBlock(shift, shift, props.schedule_start_date)
                blocks.forEach(block => {
                    block.group = 'b'
                    scheduleDayTimeDict.value[block.day_of_week]['live_in_0'].forEach(info => {
                        if (util.schedule.isTimeOverlap(block.start_time, block.duration, info.start_time, info.duration)) {
                            block.overlaps = true
                        }
                    })
                    scheduleDayTimeDict.value[block.day_of_week]['live_out_0'].forEach(info => {
                        if (util.schedule.isTimeOverlap(block.start_time, block.duration, info.start_time, info.duration)) {
                            block.overlaps = true
                        }
                    })
                })
                data = data.concat(blocks)
            })

            return data
        })
    }, {immediate: true, deep: true})
</script>

<template>
    <LatteSchedule :start_date="schedule_start_date">
        <ol
            class="col-start-1 col-end-2 row-start-1 grid grid-cols-1 sm:grid-cols-7 sm:pr-6 overflow-hidden"
            style="grid-template-rows: 0.4rem repeat(288, minmax(0, 1fr)) auto"
        >
            <LiveInViewBlock
                v-for="block in liveInBlocks"
                :schedule_start_date="props.schedule_start_date"
                :block="block"
            />

            <LiveOutViewBlock
                v-for="block in liveOutGroupOneBlocks"
                :schedule_start_date="props.schedule_start_date"
                :block="block"
            />

            <LiveOutViewBlock
                v-for="block in liveOutGroupTwoBlocks"
                :schedule_start_date="props.schedule_start_date"
                :block="block"
                overlap_class="ml-8"
            />

            <ClientCaregiverViewBlock
                v-for="block in clientShiftBlocks"
                :schedule_start_date="props.schedule_start_date"
                :block="block"
            />
        </ol>
    </LatteSchedule>
</template>
