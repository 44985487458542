export function useJobRepo() {
    const schedule = {}

    function computeKey(resource, action) {
        return `${resource}_${action}`
    }

    function getJob(resource, action) {
        const key = computeKey(resource, action)

        return !schedule[key] ? null : schedule[key]
    }

    function addJob(resource, action, requestData, handleAdditionalData, handle, metaData) {
        const key = computeKey(resource, action)

        if (!schedule[key]) {
            schedule[key] = {
                'request_data' : requestData,
                'handle_additional_data' : handleAdditionalData,
                'meta_data' : metaData
            }
        } else {
            if (requestData.length != schedule[key].request_data.length) {
                console.error('incompatible job data')
                return
            }

            for (const i in requestData) {
                if (Array.isArray(requestData[i])) {
                    schedule[key]['request_data'][i] = requestData[i]
                } else if (typeof requestData[i] == 'object') {
                    if (requestData[i] == null) {
                        schedule[key]['request_data'][i] = null
                    } else {
                        schedule[key]['request_data'][i] = {...schedule[key]['request_data'][i], ...requestData[i]}
                    }
                } else {
                    schedule[key]['request_data'][i] = requestData[i]
                }
            }

            schedule[key]['handle_additional_data'] = handleAdditionalData
            schedule[key]['meta_data'] = metaData
        }
        schedule[key]['handle'] = handle
    }

    function replaceJobData(resource, action, requestData) {
        const key = computeKey(resource, action)
        if (!schedule[key]) {
            return
        }

        schedule[key]['request_data'] = requestData
    }

    function removeJob(resource, action) {
        const key = computeKey(resource, action)

        const job = schedule[key]
        delete schedule[key]

        return job
    }

    function doesJobExistForResource(resource, action) {
        const key = computeKey(resource, action)

        if (schedule[key]) {
            return true
        }

        return false
    }

    function retrieveJob(resource, action) {
        const key = computeKey(resource, action)

        let job = null

        if (schedule[key]) {
            job = schedule[key]
            delete schedule[key]
        }

        return job
    }

    function isEmpty() {
        return Object.keys(schedule).length == 0
    }

    return {
        getJob, addJob, removeJob, retrieveJob, replaceJobData, doesJobExistForResource, isEmpty
    }
}
