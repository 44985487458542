<script setup>

    import { watch, ref } from 'vue'
    import { util } from '@/Helpers'
    import { storeToRefs } from 'pinia'
    import { useElementHover } from '@vueuse/core'
    import { useCaregiverSingleStore } from '@/Stores'

    const caregiverSingleStore = useCaregiverSingleStore()
    const { userFocusedOn } = storeToRefs(caregiverSingleStore)

    const props = defineProps({
        isCurrentOrFutureWeek: {
            type: Boolean
        },
        block: {
            type: Object
        },
        size: {
            type: String
        }
    })

    const liveOutScheduleViewBlock = ref(null)
    const blockState = useElementHover(liveOutScheduleViewBlock, { delayEnter: 60, delayLeave: 60 })

    watch(blockState, (newValue, oldValue) => {
        if (blockState.value == true) {
            userFocusedOn.value = 'live-out'
        } else {
            userFocusedOn.value = null
        }
    }, { immediate: true })


</script>

<template>
    <div
        ref="liveOutScheduleViewBlock"
        :class="[
            'overflow-hidxden text-lifeworx-blue-600',
            {
                'w-36': size == '2xl',

            }
        ]"
    >

        <div
            :class="[
                {
                    'h-full w-full rounded-[inherit] pattern-diagonal-lines pattern-lifeworx-green-600 pattern-bg-transparent pattern-opacity-20 pattern-size-2 pointer-events-none': block.group == 'b'
                }
            ]"
        />

        <div :class="[
            'absolute top-1',
            {
                'flex flex-col items-stretch': size == 'xs',
                'flex flex-col items-stretch space-y-0.5': size == 'xs',
                'absolute left-0.5 top-0 flex flex-col items-stretch space-y-0.5': size == 'sm' && isCurrentOrFutureWeek,
                'space-y-0.5': size == 'md' && isCurrentOrFutureWeek,
                'space-y-1': size == 'lg' && isCurrentOrFutureWeek,
                'space-y-1': size == 'xl' && isCurrentOrFutureWeek,
                'space-y-2': size == '2xl' && isCurrentOrFutureWeek,
                'relative h-full': !isCurrentOrFutureWeek,
            }
        ]">

            <!-- Time -->
            <div v-if="['2xs', 'xs', 'sm', 'md', 'lg', 'xl', '2xl'].includes(size) && ((block.continuation && block.day_of_week == 'sunday') || (block.duration > block.total_duration - block.duration) || (block.duration >= block.total_duration - block.duration && block.continues))">
                <span
                    :class="[
                        'whitespace-nowrap font-bold',
                        {
                            'text-[0.75rem] font-bold my-0 -mt-1 leading-none inline-block scale-90': size == '2xs',
                            'text-xs font-bold my-0 leading-none': size == 'xs',
                            'text-base font-bold my-0 leading-snug': ['sm', 'md'].includes(size),
                            'text-base font-bold my-0 leading-tight': size == 'lg',
                            'text-base font-bold my-1 leading-tight': size == 'xl',
                            'text-xl font-bold my-1 leading-tight': size == '2xl',
                        }
                    ]"
                >
                    {{ util.date.to12CycleTimeParts(block.continuation ? block.shift_start_time : block.start_time)[0] }}<span class="text-xs">{{ util.date.to12CycleTimeParts(block.continuation ? block.shift_start_time : block.start_time)[1] }}</span><br />

                    &ndash;{{ util.date.to12CycleTimeParts(util.date.timeAfterMinute(block.start_time, block.continues ? block.duration+block.continuation_duration : block.duration))[0] }}<span class="text-xs">{{ util.date.to12CycleTimeParts(util.date.timeAfterMinute(block.start_time, block.continues ? block.duration+block.continuation_duration : block.duration))[1] }}</span>
                </span>
            </div>

        </div>
    </div>

</template>
