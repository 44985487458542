<script setup>

    import { ExclamationTriangleIcon, CheckCircleIcon, XMarkIcon } from '@heroicons/vue/24/outline'

    import { useNotificationStore, useViewStore } from '@/Stores'
    import { storeToRefs } from 'pinia'

    const notificationStore = useNotificationStore()
    const viewStore = useViewStore()

    const { activeNotifications } = storeToRefs(notificationStore)
    const { isEmbedded } = storeToRefs(viewStore)

</script>

<template>

    <div
        aria-live="assertive"
        :class="[
            'pointer-events-none fixed inset-0 flex sm:items-start',
            {
                'top-48 md:top-20 px-4 py-6 sm:p-6': !isEmbedded,
                'top-0 py-8 px-4': isEmbedded
            }
        ]"
    >
        <div class="flex w-full flex-col items-end space-y-2">

            <template v-for="(notification, index) in activeNotifications">
                <transition
                    enter-active-class="transform ease-out duration-300 transition"
                    enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:-translate-y-2"
                    enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
                    leave-active-class="transition ease-in duration-100"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                >
                    <div
                        v-if="notification.show == true && notification.expires > new Date()"
                        :class="[
                            'pointer-events-auto cursor-default w-full max-w-sm overflow-hidden ring-1 rounded-lg drop-shadow-lg ',
                            {
                                'bg-white ring-lifeworx-red-500': notification.variant == 'error' && !isEmbedded,
                                'bg-white ring-lifeworx-green-600 ring-opacity-50': notification.variant == 'message' && !isEmbedded,
                                'bg-lifeworx-red-500 ring-white ': notification.variant == 'error' && isEmbedded,
                                'bg-lifeworx-green-600 ring-white': notification.variant == 'message' && isEmbedded,
                            }
                        ]"
                    >

                        <div class="p-4">
                            <div class="flex items-start pt-0.5">
                                <div class="flex-shrink-0">
                                    <CheckCircleIcon
                                        v-if="notification.variant == 'message'"
                                        :class="[
                                            'h-6 w-6',
                                            {
                                                'text-lifeworx-green-600': !isEmbedded,
                                                'text-white': isEmbedded,
                                            }
                                        ]"
                                        aria-hidden="true"
                                    />
                                    <ExclamationTriangleIcon
                                        v-if="notification.variant == 'error'"
                                        :class="[
                                            'h-6 w-6',
                                            {
                                                'text-lifeworx-red-500': !isEmbedded,
                                                'text-white': isEmbedded,
                                            }
                                        ]"
                                        aria-hidden="true"
                                    />
                                </div>
                                <div class="ml-3 w-0 flex-1 pt-0.5">
                                    <p
                                        :class="[
                                            'text-sm font-semibold tracking-wide',
                                            {
                                                'text-lifeworx-red-500': notification.variant == 'error' && !isEmbedded,
                                                'text-lifeworx-green-600': notification.variant == 'message' && !isEmbedded,
                                                'text-white': isEmbedded,
                                            }
                                        ]"
                                    >
                                        {{ notification.message }}
                                    </p>
                                    <p
                                        v-if="notification.details"
                                        :class="[
                                            'mt-1 text-sm',
                                            {
                                                'text-stone-700': !isEmbedded,
                                                'text-white': isEmbedded
                                            }
                                        ]"
                                    >
                                        {{ notification.details }}
                                    </p>
                                </div>

                                <div class="ml-4 flex flex-shrink-0 -mt-0.5">
                                    <button
                                        type="button"
                                        @click="notificationStore.remove(index)"
                                        :class="[
                                            'inline-flex rounded-md p-1 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2',
                                            {
                                                'text-stone-400 hover:text-stone-600 hover:bg-stone-200 ring-stone-400': !isEmbedded,
                                                'text-white opacity-50 hover:bg-white hover:opacity-100 hover:bg-opacity-100 ring-white ring-opacity-50 hover:ring-opacity-100': isEmbedded,
                                                'hover:text-lifeworx-red-500': notification.variant == 'error' && isEmbedded,
                                                'hover:text-lifeworx-green-600': notification.variant == 'message' && isEmbedded
                                            }
                                        ]"
                                    >
                                        <span class="sr-only">Close</span>
                                        <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                                    </button>
                                </div>

                            </div>

                        </div>

                    </div>
                </transition>
            </template>

        </div>
    </div>

</template>
