<script setup>

    import { ref, watch } from 'vue'
    import { useElementHover } from '@vueuse/core'
    import { deepCopy } from '@/Helpers';
    import LwxSelect from '@/Components/Forms/LwxSelect.vue';
    import InputLabel from '@/Components/Forms/InputLabel.vue';
    import Checkbox from '@/Components/Forms/Checkbox.vue';
    import WeekDaySelect from '@/Components/Forms/WeekDaySelect.vue';
    import '@vuepic/vue-datepicker/dist/main.css'
    import { IconCalendarAdd28Regular } from '@iconify-prerendered/vue-fluent'
    import AreaLabel from '@/Components/AreaLabel.vue';
    import { useConfigStore, useCaregiverSingleStore } from '@/Stores';
    import { storeToRefs } from 'pinia';
    import RangeSlider from '@/Components/Forms/RangeSlider.vue';
    import NumberInput from '@/Components/Forms/NumberInput.vue';
    import TimeRangePickerTz from '@/Components/Forms/TimeRangePickerTz.vue';

    const emit = defineEmits(['schedule-data:change', ['preference-data:change']])

    const configStore = useConfigStore()
    const { config } = storeToRefs(configStore)

    const caregiverSingleStore = useCaregiverSingleStore()
    const { userFocusedOn } = storeToRefs(caregiverSingleStore)

    const liveInShiftBuilder = ref(null)
    const liveOutShiftBuilder = ref(null)

    const liveInState = useElementHover(liveInShiftBuilder)
    const liveOutState = useElementHover(liveOutShiftBuilder)

    watch([liveInState, liveOutState], (newValue, oldValue) => {
        if (liveInState.value == true) {
            userFocusedOn.value = 'live-in'
        } else if (liveOutState.value == true) {
            userFocusedOn.value = 'live-out'
        } else {
            userFocusedOn.value = null
        }
    }, { immediate: true })

    const props = defineProps({
        caregiver: {
            type: Object,
            required: true
        },
        start_date: {
            type: [String, null],
            required: true,
        },
        schedule: {
            type: Object,
            required: true
        }
    })

    const defaultPreferenceState = {
        availability_pref_live_in: false,
        availability_pref_live_out: false,
        availability_pref_day_work: false,
        availability_pref_night_work: false,
        availability_pref_fill_ins_flexible: false,
        availability_pref_max_travel_time: 40,
        availability_pref_min_hourly_rate: null,
        availability_pref_min_daily_rate: null,
        availability_pref_live_in_min_days_per_week: 2,
        availability_pref_live_in_max_days_per_week: 7,
        availability_pref_live_out_min_days_per_week: 2,
        availability_pref_live_out_max_days_per_week: 7,
        availability_pref_live_out_min_hours_per_day: 4,
        availability_pref_live_out_max_hours_per_day: 12,
        availability_notes: null
    }

    const preferenceState = ref({...defaultPreferenceState})
    const scheduleState = ref(deepCopy(props.schedule))

    const maxTravelTimes = [{
        id: 20,
        name: '20 mins'
    }, {
        id: 40,
        name: '40 mins'
    }, {
        id: 60,
        name: '60 mins'
    }, {
        id: 61,
        name: '>60 mins'
    }]

    function extractOutput() {
        const info = deepCopy(preferenceState.value)

        info.availability_pref_max_travel_time = info.availability_pref_max_travel_time ? getMaxTravelTimeValue() : null

        return info
    }

    function getMaxTravelTimeValue() {
        if (preferenceState.value.availability_pref_max_travel_time.id == 61) {
            return '>60'
        } else {
            return preferenceState.value.availability_pref_max_travel_time.id === null
                ? null
                : preferenceState.value.availability_pref_max_travel_time.id.toString()
        }
    }

    watch(() => props.caregiver, (newValue) => {
        const newState = deepCopy(newValue)

        if (newState.availability_pref_max_travel_time) {
            newState.availability_pref_max_travel_time = maxTravelTimes.find(option =>
                newState.availability_pref_max_travel_time === '>60'
                    ? option.id === 61
                    : option.id === parseInt(newState.availability_pref_max_travel_time)
            ) || { id: null, name: null };
        }

        preferenceState.value = newState
    }, {immediate: true, deep: true})

    watch(() => preferenceState.value, (newValue) => {
        emit('preference-data:change', extractOutput(newValue))
    }, {immediate: true, deep: true})

    watch(() => scheduleState.value, (newValue) => {
        emit('schedule-data:change', deepCopy(newValue))
    }, {immediate: true, deep: true})

    watch(() => props.schedule, (newValue) => {
        scheduleState.value = deepCopy(newValue)
    }, {immediate: true, deep: true})

</script>

<template>
    <div class="w-full p-5 flex gap-x-5">

        <div class="flex flex-col w-3/12 h-full gap-y-5">

            <div>
                <div class="flex w-full gap-2 mb-1">
                    <div class="w-1/2">
                        <Checkbox
                            id="grid-availability_pref_live_in"
                            v-model="preferenceState.availability_pref_live_in"
                            label="Live-In"
                        />
                    </div>
                    <div class="w-1/2">
                        <Checkbox
                            id="grid-availability_pref_day_work"
                            v-model="preferenceState.availability_pref_day_work"
                            label="Days"
                        />
                    </div>
                </div>
                <div class="flex w-full gap-2 mb-2">
                    <div class="w-1/2">
                        <Checkbox
                            id="grid-availability_pref_live_out"
                            v-model="preferenceState.availability_pref_live_out"
                            label="Live-Out"
                        />
                    </div>
                    <div class="w-1/2">
                        <Checkbox
                            id="grid-availability_pref_night_work"
                            v-model="preferenceState.availability_pref_night_work"
                            label="Nights"
                        />
                    </div>
                </div>
                <div class="w-full">
                    <Checkbox
                        id="grid-availability_pref_fill_ins_flexible"
                        v-model="preferenceState.availability_pref_fill_ins_flexible"
                        label="Flexible for Fill-ins"
                    />
                </div>
            </div>

            <div>
                <div class="mb-4">
                    <InputLabel
                        for="grid-availability_pref_max_travel_time"
                        value="Max Travel Time"
                        palette="blue"
                    >
                        <LwxSelect
                            class="mt-1"
                            id="grid-availability_pref_max_travel_time"
                            v-model="preferenceState.availability_pref_max_travel_time"
                            :options="maxTravelTimes"
                        />
                    </InputLabel>
                </div>
                <div class="">
                    <AreaLabel value="Minimum Rates" class="!text-lifeworx-blue-800" />
                    <div class="flex gap-2">
                        <NumberInput
                            id="grid-availability_pref_min_hourly_rate"
                            v-model="preferenceState.availability_pref_min_hourly_rate"
                            input_class="font-semibold"
                            currency="$"
                            unit="hr"
                        />

                        <NumberInput
                            id="grid-availability_pref_min_daily_rate"
                            v-model="preferenceState.availability_pref_min_daily_rate"
                            input_class="font-semibold"
                            currency="$"
                            unit="day"
                        />
                    </div>
                </div>
            </div>
            <div>
                <InputLabel
                    for="grid-availability_notes"
                    value="Notes"
                    palette="blue"
                >
                    <textarea
                        id="grid-availability_notes"
                        class="
                            border-stone-400 placeholder:text-stone-400 focus:border-lifeworx-blue-600 focus:ring-lifeworx-blue-600 focus:ring-1 focus:outline-none rounded-md shadow-sm peer tracking-wide
                            w-full px-2.5 py-1.5 text-sm min-h-16
                        "
                        v-model="preferenceState.availability_notes"
                    />
                </InputLabel>
            </div>
        </div>

        <div
            ref="liveInShiftBuilder"
            class="flex w-3/12 h-full"
        >
            <div
                :class="[
                    'flex w-full h-full rounded-lg bg-lifeworx-purple-600 border-2 border-dashed border-lifeworx-purple-600 border-opacity-10 p-5 flex-col gap-y-3',
                    {
                        'border-opacity-50 bg-opacity-10': userFocusedOn == 'live-in',
                        'border-opacity-10 bg-opacity-5': userFocusedOn !== 'live-in'
                    }
                ]">

                <h3 class="text-lifeworx-purple-600 w-full flex font-bold text-xl ">
                    <IconCalendarAdd28Regular class="flex-none flex-shrink-0 inline-flex h-7 w-7 mr-2" />
                    Live-In Shifts
                </h3>
                <div class="mt-5">
                    <div class="w-full mb-3">
                        <h4 class="font-semibold text-sm mb-1 text-lifeworx-blue-800">Earliest Start</h4>
                        <TimeRangePickerTz
                            :startTime="scheduleState.live_in[0].start_time"
                            :duration="scheduleState.live_in[0].duration"
                            stayType="live_in"
                            :minTime="{hours:6}"
                            :maxTime="{hours:9}"
                            @update:startTime="(newStartTime) => scheduleState.live_in[0].start_time = newStartTime"
                            :enable-minutes="false"
                        />
                        <WeekDaySelect
                            class="rounded-b-lg rounded-t-none border-t-0"
                            v-model="scheduleState.live_in[0].day_of_week"
                        />
                    </div>
                    <div class="w-full pt-2 border-t border-lifeworx-purple-600">
                        <div class="flex justify-between mb-2">
                            <span class="text-lifeworx-blue-800 font-semibold text-sm">Days per Week</span>
                            <template v-if="preferenceState.availability_pref_live_in_min_days_per_week !== null && preferenceState.availability_pref_live_in_min_days_per_week == preferenceState.availability_pref_live_in_max_days_per_week">
                                <span class="font-semibold">{{ preferenceState.availability_pref_live_in_min_days_per_week }}</span>
                            </template>
                            <template v-else>
                                <span class="font-semibold">{{ preferenceState.availability_pref_live_in_min_days_per_week }}&ndash;{{ preferenceState.availability_pref_live_in_max_days_per_week }}</span>
                            </template>
                        </div>
                        <RangeSlider
                            :min="2"
                            :max="7"
                            :current_min="preferenceState.availability_pref_live_in_min_days_per_week"
                            :current_max="preferenceState.availability_pref_live_in_max_days_per_week"
                            @update:current_min="(value) => preferenceState.availability_pref_live_in_min_days_per_week = value"
                            @update:current_max="(value) => preferenceState.availability_pref_live_in_max_days_per_week = value"
                        />
                    </div>
                </div>

            </div>
        </div>

        <div
            ref="liveOutShiftBuilder"
            class="flex w-6/12 h-full flex-col"
        >
            <div
                :class="[
                    'flex w-full h-full rounded-lg bg-lifeworx-green-600 border-2 border-dashed border-lifeworx-green-600 p-5 flex-col gap-y-3',
                    {
                        'border-opacity-50 bg-opacity-10': userFocusedOn == 'live-out',
                        'border-opacity-10 bg-opacity-5': userFocusedOn !== 'live-out'
                    }
                ]">

                <h3 class="text-lifeworx-green-600 w-full flex font-bold text-xl ">
                    <IconCalendarAdd28Regular class="flex-none flex-shrink-0 inline-flex h-7 w-7 mr-2" />
                    Live-Out Shifts
                </h3>
                <div class="flex w-full gap-5">
                    <div class="w-6/12">
                        <div class="w-full mb-3">
                            <div class="flex pr-2">
                                <h4 class="flex flex-grow font-semibold text-sm mb-1 text-lifeworx-blue-800">Earliest Start <br>&nbsp;&nbsp;&nbsp;&mdash; Latest End</h4>
                                <div class="flex mt-1.5 w-8 h-8 items-center justify-center flex-grow-0 border-lifeworx-green-600 border-2 border-opacity-40 border-dashed rounded-md scale-75">
                                    <div class="flex w-6 h-6 rounded-md bg-lifeworx-green-600 bg-opacity-15"></div>
                                </div>
                            </div>
                            <TimeRangePickerTz
                                :startTime="scheduleState.live_out[0].start_time"
                                :duration="scheduleState.live_out[0].duration"
                                stayType="live_out"
                                @update:startTime="(newStartTime) => scheduleState.live_out[0].start_time = newStartTime"
                                @update:duration="(newDuration) => scheduleState.live_out[0].duration = newDuration"
                            />
                            <WeekDaySelect
                                class="rounded-b-lg rounded-t-none border-t-0"
                                v-model="scheduleState.live_out[0].day_of_week"
                            />
                        </div>
                        <div class="w-full pt-2 border-t border-lifeworx-green-600">
                            <div class="flex justify-between mb-2">
                                <span class="text-lifeworx-blue-800 font-semibold text-sm">Hours per Day</span>
                                <template v-if="preferenceState.availability_pref_live_out_min_hours_per_day !== null && preferenceState.availability_pref_live_out_min_hours_per_day == preferenceState.availability_pref_live_out_max_hours_per_day">
                                    <span class="font-semibold">{{ preferenceState.availability_pref_live_out_min_hours_per_day }}</span>
                                </template>
                                <template v-else>
                                    <span class="font-semibold">{{ preferenceState.availability_pref_live_out_min_hours_per_day }}&ndash;{{ preferenceState.availability_pref_live_out_max_hours_per_day }}</span>
                                </template>
                            </div>
                            <RangeSlider
                                :min="4"
                                :max="16"
                                :current_min="preferenceState.availability_pref_live_out_min_hours_per_day"
                                :current_max="preferenceState.availability_pref_live_out_max_hours_per_day"
                                @update:current_min="(value) => preferenceState.availability_pref_live_out_min_hours_per_day = value"
                                @update:current_max="(value) => preferenceState.availability_pref_live_out_max_hours_per_day = value"
                            />
                        </div>
                    </div>
                    <div class="w-6/12">
                        <div class="w-full mb-3">
                            <div class="flex pr-2">
                                <h4 class="flex flex-grow font-semibold text-sm mb-1 text-lifeworx-blue-800">Earliest Start <br>&nbsp;&nbsp;&nbsp;&mdash; Latest End</h4>
                                <div class="flex mt-1.5 w-8 h-8 items-center justify-center flex-grow-0 border-lifeworx-green-600 border-2 border-opacity-40 border-dashed rounded-md scale-75">
                                    <div class="flex w-6 h-6 rounded-md pattern-diagonal-lines pattern-lifeworx-green-600 pattern-bg-transparent pattern-opacity-100 pattern-size-2 bg-lifeworx-green-600 bg-opacity-10"></div>
                                </div>
                            </div>
                            <TimeRangePickerTz
                                :startTime="scheduleState.live_out[1].start_time"
                                :duration="scheduleState.live_out[1].duration"
                                stayType="live_out"
                                @update:startTime="(newStartTime) => scheduleState.live_out[1].start_time = newStartTime"
                                @update:duration="(newDuration) => scheduleState.live_out[1].duration = newDuration"
                            />
                            <WeekDaySelect
                                class="rounded-b-lg rounded-t-none border-t-0"
                                v-model="scheduleState.live_out[1].day_of_week"
                            />
                        </div>
                        <div class="w-full pt-2 border-t border-lifeworx-green-600">
                            <div class="flex justify-between mb-2">
                                <span class="text-lifeworx-blue-800 font-semibold text-sm">Days per Week</span>
                                <template v-if="preferenceState.availability_pref_live_out_min_days_per_week !== null && preferenceState.availability_pref_live_out_min_days_per_week == preferenceState.availability_pref_live_out_max_days_per_week">
                                    <span class="font-semibold">{{ preferenceState.availability_pref_live_out_min_days_per_week }}</span>
                                </template>
                                <template v-else>
                                    <span class="font-semibold">{{ preferenceState.availability_pref_live_out_min_days_per_week }}&ndash;{{ preferenceState.availability_pref_live_out_max_days_per_week }}</span>
                                </template>
                            </div>
                            <RangeSlider
                                :min="2"
                                :max="7"
                                :current_min="preferenceState.availability_pref_live_out_min_days_per_week"
                                :current_max="preferenceState.availability_pref_live_out_max_days_per_week"
                                @update:current_min="(value) => preferenceState.availability_pref_live_out_min_days_per_week = value"
                                @update:current_max="(value) => preferenceState.availability_pref_live_out_max_days_per_week = value"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
