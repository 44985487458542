<script setup>

    import { computed, ref, watch } from 'vue'
    import VueDatePicker from '@vuepic/vue-datepicker'
    import '@vuepic/vue-datepicker/dist/main.css'
    import { ClockIcon } from '@heroicons/vue/24/outline'
    import { util } from '@/Helpers'
    import moment from 'moment-timezone';

    const props = defineProps({
        startTime: {
            type: [String, null],
            default: '09:00'
        },
        duration: {
            type: [Number, null],
            default: 720
        },
        stayType: {
            type: [String, null],
            default: 'live_out'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        teleport: {
            type: [String, Boolean],
            default: false
        },
        minTime: {
            type: [Object, null],
            default: null
        },
        maxTime: {
            type: [Object, null],
            default: null
        },
        enableMinutes: {
            type: Boolean,
            default: true
        }
    })

    const liveOutTimePicker = ref(null)
    const multiUpdate = ref(false)
    const pickerOpen = ref(false)

    const emit = defineEmits(['update:startTime', 'update:duration'])

    const isMultiple = computed(() => {
        return (props.startTime == null || props.duration == null);
    });

    const internalValue = computed({
        get: () => {
            if (props.stayType == 'live_out') {
                if (isMultiple.value) {
                    multiUpdate.value = false
                } else {
                    return util.schedule.momentAndDurationToRangeTime(
                        moment(props.startTime, 'HH:mm:ssZ'),
                        props.duration
                    )
                }
            } else {
                return util.schedule.momentAndDurationToRangeTime(moment(props.startTime, 'HH:mm:ssZ'), 24 * 60)[0]
            }
        },
        set: (value) => {
            if (value == null) { return false; }
            if (props.stayType == 'live_out') {
                const oldStartHour = parseFloat(props.startTime.split(':')[0])
                const oldStartMinute = parseFloat(props.startTime.split(':')[1])
                let duration = 16 * 60

                if (value[0].hours == value[1].hours && value[0].minutes == value[1].minutes && value[0].seconds == value[1].seconds) {
                    duration = 12 * 60
                } else if (moment(value[0].hours + ':' + value[0].minutes, 'k:m') > moment(value[1].hours + ':' + value[1].minutes, 'k:m')){
                    duration = moment(value[1].hours + ':' + value[1].minutes, 'k:m').clone().add(24, 'hours').diff(moment(value[0].hours + ':' + value[0].minutes, 'k:m'), 'minutes')
                } else {
                    duration = moment(value[1].hours + ':' + value[1].minutes, 'k:m').diff(moment(value[0].hours + ':' + value[0].minutes, 'k:m'), 'minutes')
                }

                //User change start time
                if (value[0].hours != oldStartHour || value[0].minutes != oldStartMinute) {
                    emit('update:startTime', moment(value[0].hours + ':' + value[0].minutes, 'k:m').format('HH:mm'))

                    const reverseStartHour = `${((value[0].hours + 12) % 24).toString().padStart(2, '0')}:${value[0].minutes.toString().padStart(2, '0')}`
                    const originalHourMinute =  props.startTime.split(':').slice(0, 2).join(':')
                    if (reverseStartHour == originalHourMinute && props.duration == 12 * 60) {
                        duration = props.duration
                    } else if (duration > 16 * 60) {
                        duration = 16 * 60
                    }
                } else if (duration > 16 * 60) {
                    let newHour = value[1].hours - 16
                    newHour = newHour > 0 ? newHour : newHour + 24
                    emit('update:startTime', moment(newHour + ':' + value[1].minutes, 'k:m').format('HH:mm'))
                    duration = 16 * 60
                }

                emit('update:duration', duration)
            } else {
                emit('update:startTime', moment(value.hours + ':' + value.minutes, 'k:m').format(util.date.time_with_tz_format))
                emit('update:duration', 24 * 60)
            }
        }
    })

    function resetMenu() {
        liveOutTimePicker.value.closeMenu()
        setTimeout(() => liveOutTimePicker.value.openMenu(), 1 )
    }

    watch(() => props, (newValue) => {
        if (liveOutTimePicker.value && pickerOpen.value) {
            if (newValue.duration == 16 * 60) {
                resetMenu()
            }

            const originalHourMinute =  newValue.startTime.split(':').slice(0, 2).join(':')
            if (newValue.duration == 12 * 60 && originalHourMinute == '00:00') {
                resetMenu()
            }
        }
    }, {immediate:true, deep:true})

    function multipleClick() {
        if (isMultiple.value && !multiUpdate.value) {
            multiUpdate.value = true
            liveOutTimePicker.value.closeMenu()
            emit('update:startTime',  moment('09:00', "HH:mm").format(util.date.time_with_tz_format))
            emit('update:duration', 720)
            setTimeout(() => liveOutTimePicker.value.openMenu(), 1 )
        }
    }

</script>

<template>
    <div>

        <VueDatePicker
            v-if="props.stayType == 'live_out'"
            v-model="internalValue"
            time-picker
            :clearable="false"
            :range="{ disableTimeRangeValidation: true }"
            :disabled="props.disabled"
            minutes-increment="15"
            minutes-grid-increment="15"
            :is24="false"
            :autoApply="true"
            :teleport="props.teleport"
            placeholder="Multiple"
            format="h:mmaaa"
            ref="liveOutTimePicker"
            :ui="{ input: 'font-semibold rounded-lg text-center border-stone-400 focus:border focus:border-lifeworx-blue-600 focus:ring-0 focus:outline focus:outline-3' }"
            :transitions="props.duration == 16 * 60 || isMultiple ? false : true"
            @open="pickerOpen = !pickerOpen, multipleClick"
            @closed="pickerOpen = !pickerOpen"
            :enable-minutes=props.enableMinutes
            @am-pm-change="resetMenu"
        >
            <template #input-icon>
                <ClockIcon class="inline-flex mx-2 h-5 w-5 -mt-0.5 text-stone-500 input-slot-image" />
            </template>
        </VueDatePicker>

        <VueDatePicker
            v-else
            v-model="internalValue"
            time-picker
            :clearable="false"
            :disabled="props.disabled"
            minutes-increment="15"
            minutes-grid-increment="15"
            :is24="false"
            :autoApply="true"
            :teleport="props.teleport"
            format="h:mmaaa"
            :ui="{ input: 'font-semibold rounded-lg text-center border-stone-400 focus:border focus:border-lifeworx-blue-600 focus:ring-0 focus:outline focus:outline-3' }"
            :min-time="props.minTime"
            :max-time="props.maxTime"
            :enable-minutes=props.enableMinutes
        >
            <template #input-icon>
                <ClockIcon class="inline-flex mx-2 h-5 w-5 -mt-0.5 text-stone-500 input-slot-image" />
            </template>
        </VueDatePicker>

    </div>
</template>
