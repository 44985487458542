<script setup>
    import { ref, watch } from 'vue';
    import VueSlider from 'vue-3-slider-component';
    import { XMarkIcon } from '@heroicons/vue/20/solid';

    const value = ref([0, 100]); // Range values

    const props = defineProps({
        current_min: {
            type: [Number, String, null],
            value: 0
        },
        current_max: {
            type: [Number, String, null],
            value: 100
        },
        min: {
            type: [Number, String],
            default: 0
        },
        max: {
            type: [Number, String],
            default: 100
        }
    })

    watch(props, (newValue) => {
        value.value = [newValue.current_min, newValue.current_max]
    }, { immediate: true, deep: true})

    const emit = defineEmits(['update:current_min', 'update:current_max'])

    watch(value, (newValue, oldValue) => {
        if (newValue[0] != oldValue[0]) {
            emit('update:current_min', newValue[0])
        }

        if (newValue[1] != oldValue[1]) {
            emit('update:current_max', newValue[1])
        }
    })

    function handleClearValue() {
        value.value = [null, null]
    }
</script>

<template>
    <div class="flex items-center gap-2">
        <div class="flex-grow pl-1">
            <vue-slider
                v-model="value"
                :min="props.min"
                :max="props.max"
                :range="true"
                :lazy="true"
                :tooltip="props.current_min == null && props.current_max == null ? 'focus' : 'hover'"
            />
        </div>
        <div class="w-5 relative">
            <button
                @click="handleClearValue"
                :disabled="props.current_min == null && props.current_max == null"
                :class="[
                    'absolute left-[5px] bottom-[-10px] rounded-md cursor-pointer disabled:cursor-default enabled:text-lifeworx-blue-800 text-stone-300 enabled:hover:text-lifeworx-red-600 enabled:hover:bg-white/50'
                ]"
            >
                <XMarkIcon
                    class="w-5 h-5"
                />
            </button>
        </div>
    </div>
</template>
