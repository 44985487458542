import { computed, reactive, ref } from 'vue';

import { acceptHMRUpdate, defineStore } from 'pinia';
import { useFormStatusStore } from '@/Stores'

import { useNProgress } from '@vueuse/integrations/useNProgress';
import { get, patch } from '@/Helpers/takeLatestResponseRequest';
import axios from 'axios';
import { util } from '@/Helpers';

export const useCaregiverAvailabilityStore = defineStore('caregiverAvailability', () => {
    const formStatusStore = useFormStatusStore()

    const { isLoading } = useNProgress()
    const loading = ref(isLoading)

    const caregiverAvailabilityDict = reactive({})

    function replaceLocalAvailabilities(caregiver_id, availabilities) {
        caregiverAvailabilityDict[caregiver_id] = availabilities
    }

    function applyLocalChangeToAvailability(caregiver_id, availability_uuid, availabilityData) {
        if (caregiverAvailabilityDict[caregiver_id] == undefined) {
            return
        }

        const index = caregiverAvailabilityDict[caregiver_id].findIndex(item => item.uuid == availability_uuid)
        if (index > -1) {
            Object.keys(availabilityData).forEach(key => {
                caregiverAvailabilityDict[caregiver_id][index][key] = availabilityData[key]
            })
        } else {
            caregiverAvailabilityDict[caregiver_id].push(availabilityData)
        }
    }

    const maxRetryDialogId = reactive({})
    function setMaxRetryDialogId(key, id) {
        maxRetryDialogId[key] = id
    }
    function removeMaxRetryDialogId(key) {
        maxRetryDialogId[key] = undefined
    }
    function getMaxRetryDialogId(key) {
        return maxRetryDialogId[key]
    }

    function replaceAvailability(caregiver_id, availability) {
        if (caregiverAvailabilityDict[caregiver_id] == undefined) {
            return
        }

        const index = caregiverAvailabilityDict[caregiver_id].findIndex(item => item.uuid == availability.uuid)

        if (index > -1) {
            caregiverAvailabilityDict[caregiver_id][index] = availability
        } else {
            caregiverAvailabilityDict[caregiver_id].push(availability)
        }
    }

    function computedAvailabilties(caregiver_id) {
        return computed(() => caregiverAvailabilityDict[caregiver_id] ? caregiverAvailabilityDict[caregiver_id] : [])
    }

    function computedAvailabilityMap(caregiver_id) {
        return computed(() => {
            const map = {}

            if (caregiverAvailabilityDict[caregiver_id]) {
                caregiverAvailabilityDict[caregiver_id].forEach((shift, index) => {
                    map[shift.id] = index
                })
            }

            return map
        })
    }

    async function retrieveAvailabilities(caregiver_id, formId) {
        loading.value = true
        if (formId) {
            formStatusStore.addLoading(formId)
        }

        return await new Promise((resolve, reject) => {
            get(
                `/api/v1/caregivers/${caregiver_id}/availabilities`
            ).then(response => {
                resolve(response)
            }).catch((err) => {
                reject({ "error": err })
            }).finally(() => {
                loading.value = false
                if (formId) {
                    formStatusStore.removeLoading(formId)
                }
            })
        });
    }

    async function createOrUpdateAvailability(caregiver_id, availability_uuid, data, formId) {
        loading.value = true
        if (formId) {
            formStatusStore.addLoading(formId)
        }

        return await new Promise((resolve, reject) => {
            patch(
                `/api/v1/caregivers/${caregiver_id}/availabilities/${availability_uuid}`, data
            )
            .then(response => {
                if (response.status === 201) {
                    const redirectUrl = response.headers.location
                    return axios.get(redirectUrl)
                }
                return response
            })
            .then(response => {
                resolve(response)
            }).catch((err) => {
                reject({ "error": err })
            }).finally(() => {
                loading.value = false
                if (formId) {
                    formStatusStore.removeLoading(formId)
                }
            })
        });
    }

    function defaultScheduleState(type) {
        const defaultTime = util.moment('08:00', "HH:mm")
        if (type == 'live_in') {
            return {
                uuid: crypto.randomUUID(),
                start_time: defaultTime.format(util.date.time_with_tz_format),
                duration: 24 * 60,
                stay_type: 'live_in',
                day_of_week: [],
                fe_generated_not_sync: true
            }
        }

        if (type == 'live_out') {
            return {
                uuid: crypto.randomUUID(),
                start_time: defaultTime.format(util.date.time_with_tz_format),
                duration: 12 * 60,
                stay_type: 'live_out',
                day_of_week: [],
                fe_generated_not_sync: true
            }
        }
    }

    return {
        replaceLocalAvailabilities,
        computedAvailabilties, computedAvailabilityMap,
        retrieveAvailabilities,
        defaultScheduleState,
        applyLocalChangeToAvailability, replaceAvailability, createOrUpdateAvailability,
        setMaxRetryDialogId, removeMaxRetryDialogId, getMaxRetryDialogId
    }

})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useCaregiverAvailabilityStore, import.meta.hot))
}
