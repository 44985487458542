<script setup>

    import { computed } from 'vue'
    import { util } from '@/Helpers'
    import moment from 'moment'
    import { storeToRefs } from 'pinia'
    import LiveOutScheduleViewBlock from '@/Components/Caregiver/LiveOutScheduleViewBlock.vue'
    import { useCaregiverSingleStore } from '@/Stores'

    const caregiverSingleStore = useCaregiverSingleStore()
    const { userFocusedOn } = storeToRefs(caregiverSingleStore)

    const props = defineProps({
        schedule_start_date: {
            type: String,
            required: true
        },
        block: {
            type: Object,
            required: true
        },
        overlap_class: {
            type: String,
            default: 'ml-4'
        }
    })

    const isCurrentOrFutureWeek = computed(() => {
        const lastSunday = moment(util.date.lastSunday(), util.date.date_format)
        const startDate = moment(props.schedule_start_date, util.date.date_format)

        return startDate.isSameOrAfter(lastSunday);
    })

</script>


<template>
    <li
        class="relative flex group justify-center ml-5 mr-0.5"
        :class="[
            `col-start-${util.date.dateDiff(schedule_start_date, block.start_date) + 1}`,
            {
                'z-40': userFocusedOn == 'live-out',
                'z-10': userFocusedOn == 'live-in',
                'z-90': userFocusedOn == null,
            }
        ]"
        :style="`grid-row: ${util.schedule.startWithTz(block.start_time)} / span ${util.schedule.span(block.duration)}`"
    >
        <LiveOutScheduleViewBlock
            :isCurrentOrFutureWeek="isCurrentOrFutureWeek"
            :block="block"
            :size="util.schedule.sizeOf(block)"
            :class="[
                'group absolute flex inset-0.5 flex-col overflow-y-auto text-xs whitespace-nowrap cursor-default border-opacity-10 border-dashed bg-lifeworx-green-600 border-lifeworx-green-600 text-lifeworx-green-600',
                {
                    'rounded-t-lg': block.continues,
                    'rounded-b-lg': block.continuation,
                    'rounded-lg': !block.continues && !block.continuation,
                    'p-0.5 border-2': ['3xs', '2xs', 'xs'].includes(util.schedule.sizeOf(block)),
                    'p-1 border-2': ['sm', 'md', 'lg', 'xl', '2xl'].includes(util.schedule.sizeOf(block)),
                    // [overlap_class]: block.overlaps,
                    'backdrop-blur-sm bg-opacity-[0.2]': block.id && block.overlaps,
                    'backdrop-brightness-200 backdrop-opacity-75 backdrop-blur-sm bg-opacity-[0.2] text-opacity-75': userFocusedOn !== 'live-out',
                    'backdrop-brightness-200 backdrop-opacity-75 backdrop-blur-sm border-opacity-50 bg-opacity-[0.25]': userFocusedOn == 'live-out',
                }
            ]"
        />
    </li>
</template>
