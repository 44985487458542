<script setup>

    import LwxLotus from '@/Components/LwxLotus.vue'

    import { EnvelopeIcon, PhoneArrowUpRightIcon } from '@heroicons/vue/24/outline'

    import { util } from '@/Helpers'
    import { computed } from 'vue';

    const { staff, actionable, size } = defineProps({
        staff: {
            type: Object
        },
        actionable: {
            type: Boolean,
            default: true
        },
        theme: {
            default: 'blue'
        },
        size: {
            type: String,
            default: 'sm'
        },
        wrapping: {
            type: Boolean,
            default: false
        }
    })

    const textSize = computed(() => {
        return 'text-' + size
    })

    // Reshape phone numbers if needed
    if (staff && !staff.phone_numbers.work) {
        staff.phone_numbers = util.convertArrayToObject(Object.values(staff.phone_numbers), 'type')
    }

    const phoneNumber = computed(() => {
        if (!staff.phone_numbers) {
            return null;
        }

        if (!staff.phone_numbers.work && !staff.phone_numbers.home && !staff.phone_numbers.cell) {
            return null;
        }

        if (staff.phone_numbers.work && staff.phone_numbers.work.number && staff.phone_numbers.work.number != '') {
            return staff.phone_numbers.work.number;
        }

        if (staff.phone_numbers.cell &&  staff.phone_numbers.cell.number && staff.phone_numbers.cell.number != '') {
            return staff.phone_numbers.cell.number;
        }

        if (staff.phone_numbers.home && staff.phone_numbers.home.number && staff.phone_numbers.home.number != '') {
            return staff.phone_numbers.home.number;
        }

        return null;
    })

</script>

<template>

    <div class="flex">
        <VDropdown
            v-if="actionable && staff"
            :triggers="['click', 'focus']"
            :distance="6"
            theme="lifeworx"
            placement="top"
        >

            <h4
                class="tracking-wide font-semibold text-2xs cursor-pointer group/stafflabel"
                :class="`text-lifeworx-${theme}-700 hover:text-lifeworx-${theme}-600`"
            >
                <LwxLotus class="inline-flex h-4 -mt-0.5 bg-white"
                :class="[{
                    'h-3.5': ['xs', 'sm', 'md'].includes(size),
                    'h-4': ['lg', 'xl'].includes(size),
                }]"/>
                <span class="pl-1 group-hover/stafflabel:underline">
                    {{ staff ? staff.first_name + ' ' + staff.last_name : 'Unknown' }}
                </span>
            </h4>

            <template #popper>
                <div class="p-2">
                    <a
                        :href="'mailto:' + staff.email"
                        :class="`inline-flex p-2 text-lifeworx-${theme}-700 hover:text-white`"
                    >
                        <EnvelopeIcon class="inline-flex h-6 w-6" />
                    </a>

                    <a
                        v-if="phoneNumber"
                        :href="'tel:' + phoneNumber"
                        :class="`inline-flex p-2 text-lifeworx-${theme}-700 hover:text-white`"
                        ref="reference"
                    >
                        <PhoneArrowUpRightIcon class="inline-flex h-6 w-6" />
                    </a>

                    <button
                        v-else
                        :class="`inline-flex p-2 text-lifeworx-${theme}-700 hover:text-white`"
                    >
                        <PhoneArrowUpRightIcon :class="`inline-flex h-6 w-6 text-lifeworx-${theme}-700 opacity-30 cursor-not-allowed`" />
                    </button>

                </div>
            </template>
        </VDropdown>

        <div v-else>
            <h4
                :class="[
                    `tracking-wide text-lifeworx-${theme}-700 font-semibold`,
                    textSize,
                    {
                        'whitespace-nowrap': !wrapping,
                        'text-wrap': wrapping
                    }
                ]"
            >
                <LwxLotus class="inline-flex h-3 -mt-0.5" />
                <span class="pl-1">
                    {{ staff ? staff.first_name + ' ' + staff.last_name : 'Unknown' }}
                </span>
            </h4>
        </div>

    </div>
</template>
