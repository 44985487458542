<script setup>
    import { ref, watch } from 'vue';

    const props = defineProps({
        modelValue: {
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    })

    const emit = defineEmits(['update:modelValue'])
    const onChange = () => {
        const keys = Object.keys(weekDay.value)
        const selectedDays = []
        keys.forEach(day => {
            if (weekDay.value[day] == 'yes') {
                selectedDays.push(day)
            }
        })
        emit('update:modelValue', selectedDays)
    };

    const weekDay = ref({
        sunday: 'no',
        monday: 'no',
        tuesday: 'no',
        wednesday: 'no',
        thursday: 'no',
        friday: 'no',
        saturday: 'no'
    })

    function check(days, value) {
        days.forEach(item => {
            weekDay.value[item] = value
        })
    }

    function selectAll()
    {
        if (props.disabled) {
            return
        }
        check(Object.keys(weekDay.value), 'yes')
        onChange()
    }

    function selectWeekDays()
    {
        if (props.disabled) {
            return
        }
        check(Object.keys(weekDay.value), 'no')
        check(['monday', 'tuesday', 'wednesday', 'thursday', 'friday'], 'yes')
        onChange()
    }

    function selectWeekend()
    {
        if (props.disabled) {
            return
        }
        check(Object.keys(weekDay.value), 'no')
        check(['sunday', 'saturday'], 'yes')
        onChange()
    }

    watch(() => props.modelValue, (newValue) => {
        Object.keys(weekDay.value).forEach(day => {
            weekDay.value[day] = (newValue.includes(day)) ? 'yes' : 'no'
        })
    })
</script>

<template>
    <div
        :class="[
            'bg-white rounded-lg pt-5 pb-7 px-1 border border-stone-300',
            {
                'opacity-50': props.disabled
            }
        ]"
    >
        <ul class="list-none grid grid-cols-7 gap-x-1.5 mb-2 px-3">
            <li
                v-for="day in Object.keys(weekDay)"
                :class="[
                    'col-span-1 text-center',
                    {
                        'hover:text-lifeworx-blue-700 group': !props.disabled
                    }
                ]"
            >
                <label
                    :for="`w-${day}`"
                    :class="[
                        'block pb-1.5 font-semibold text-sm text-lifeworx-blue-800',
                        {
                            'cursor-pointer group-hover:text-lifeworx-blue-600' : !props.disabled
                        }
                    ]"
                >
                    {{ day.charAt(0).toUpperCase() }}
                </label>
                <input
                    :id="`w-${day}`"
                    type="checkbox"
                    v-model="weekDay[day]"
                    true-value="yes"
                    false-value="no"
                    :disabled="props.disabled"
                    @change="onChange"
                    :class="[
                        'rounded-sm w-5 h-5 border-stone-400 text-lifeworx-blue-600 focus:ring-0 checked:border-dotted ring-offset-white', {
                            'cursor-pointer' : !props.disabled,
                            'group-hover:bg-lifeworx-blue-500 group-hover:bg-opacity-20 group-hover:border-lifeworx-blue-600': weekDay[day] == 'no'
                        }
                    ]"
                />
            </li>
        </ul>

        <div class="flex justify-around px-3 gap-x-1">
            <button
                class="cursor-pointer text-xs underline underline-offset-1 font-semibold text-lifeworx-orange-500 hover:text-lifeworx-orange-800"
                @click="selectWeekDays"
            >
                    Weekdays
            </button>
            <button
                class="cursor-pointer text-xs underline underline-offset-1 font-semibold text-lifeworx-orange-500 hover:text-lifeworx-orange-800"
                @click="selectWeekend"
            >
                    Weekends
            </button>
            <button
                class="cursor-pointer text-xs underline underline-offset-1 font-semibold text-lifeworx-orange-500 hover:text-lifeworx-orange-800"
                @click="selectAll"
            >
                    All
            </button>
        </div>

    </div>

</template>
