<script setup>

    import InputLabel from '@/Components/Forms/InputLabel.vue';
    import { ref, computed, watch } from 'vue';
    import SecondaryButton from '@/Components/Forms/SecondaryButton.vue';
    import TextInput from '@/Components/Forms/TextInput.vue';
    import { CalendarDaysIcon } from '@heroicons/vue/20/solid';

    import moment from 'moment';

    const emit = defineEmits(['schedule:confirm'])

    const props = defineProps({
        caregiver_id : {
            type: Number,
            required: true
        },
        last_confirmed_date: {
            required: true
        },
        last_confirmed_staff: {
            type: [Object, null],
            required: true
        }
    })

    const lastConfirmedDateFormatted = computed(() => {
        if (!props.last_confirmed_date) {
            return '—/—/——';
        }

        const date = new Date(props.last_confirmed_date);
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const year = date.getFullYear();
        return `${month} / ${day} / ${year}`;
    })

    const displayWarning = computed(() => {
        return props.last_confirmed_date && moment(props.last_confirmed_date).isBefore(moment().subtract(3, 'months'));
    })

    const confirmLabel = computed(() => {
        if (props.last_confirmed_date) {
            let text = 'Last Confirmed'
            return props.last_confirmed_staff ?
                text += ` by ${props.last_confirmed_staff.first_name} ${props.last_confirmed_staff.last_name.trim().charAt(0).toUpperCase()}.`
                :
                text
        }

        return 'Not Yet Confirmed'
    })

</script>

<template>
    <div class="flex flex-row border-t py-4 px-6 gap-5">
        <div class="flex flex-col w-5/12">
            <div>
                <span
                    :class="[
                        'block text-sm group mb-1 font-semibold',
                        {
                            italic: !props.last_confirmed_date,
                            'text-lifeworx-blue-800': !displayWarning,
                            'text-lifeworx-red-500': displayWarning
                        }
                    ]"
                >{{ confirmLabel }}</span>
            </div>
            <div class="flex relative gap-2">
                <div class="w-5/12">
                    <span
                        :class="[
                            'rounded-md shadow-sm w-full px-2.5 py-1.5 text-sm font-semibold flex justify-between select-none cursor-default',
                            {
                                'border-lifeworx-blue-800': !displayWarning,
                                'border-lifeworx-red-500': displayWarning,
                                'text-lifeworx-blue-800': !displayWarning,
                                'text-lifeworx-red-500': displayWarning,
                                'border': !displayWarning,
                                'border-2': displayWarning,
                                'font-bold': displayWarning
                            }
                        ]"
                    >
                        <span class="select-none cursor-default">
                            {{ lastConfirmedDateFormatted }}
                        </span>
                        <CalendarDaysIcon
                            :class="[
                                'h-5 w-5 pointer-events-none',
                                {
                                    'text-lifeworx-blue-800': !displayWarning,
                                    'text-lifeworx-red-500': displayWarning
                                }
                            ]"
                        />
                    </span>
                </div>
                <SecondaryButton
                    size="xs"
                    color="lifeworx-green"
                    :emphasize="false"
                    input_class="w-7/12 hover:text-white hover:bg-lifeworx-green-700 font-bold"
                    @click="() => { emit('schedule:confirm') }"
                >
                    Confirm Schedule Details
                </SecondaryButton>
            </div>
        </div>
        <div
            v-if="displayWarning"
            class="flex w-7/12 border border-lifeworx-red-500 border-dashed rounded-md p-3 text-xs font-semibold"
        >
            <span class="text-lifeworx-red-500 ">Warning:&nbsp;</span> <span class="text-lifeworx-blue-800">This availability information may be out of date. Please update and confirm.</span>

        </div>
    </div>
</template>
